<!--
 * @Description: 费用信息
 * @Author: ChenXueLin
 * @Date: 2021-10-19 08:58:36
 * @LastEditTime: 2022-07-15 16:43:40
 * @LastEditors: ChenXueLin
-->

<!-- 基本信息 -->
<template>
  <div v-loading="loading" :element-loading-background="loadingBackground">
    <div class="second-change-type">
      <div class="switch-page-button">
        <el-button
          round
          :class="[clickIndex == index ? 'activeButton' : '']"
          v-for="(btn, index) in btnList"
          :key="index"
          @click="switchPage(index)"
          >{{ btn.name }}</el-button
        >
      </div>
    </div>
    <section class="table-wrapper">
      <el-table border height="300px" :data="tableData" highlight-current-row>
        <el-table-column
          show-overflow-tooltip
          v-for="(column, index) in columnData"
          :key="index"
          :prop="column.fieldName"
          :label="column.fieldLabel"
          :min-width="column.width"
          :fixed="column.fixed"
          :align="column.align"
          header-align="center"
        >
          <template slot-scope="{ row }">
            <span v-if="column.fieldName === 'equipNo'">
              <el-button type="text">
                {{ row[column.fieldName] }}
              </el-button>
            </span>
            <span v-else>{{ row[column.fieldName] }}</span>
          </template>
        </el-table-column>
      </el-table>
    </section>
  </div>
  <!-- 照片信息墙 end -->
</template>

<script>
import base from "@/mixins/base";
import { printError } from "@/utils/util";
import { getTerminalPrice } from "@/api";
export default {
  name: "installPhoto",
  data() {
    return {
      clickIndex: 0,
      btnList: [
        {
          name: "服务费"
        },
        {
          name: "设备费"
        },
        {
          name: "安装费"
        }
      ],
      serviceColumnData: [
        {
          fieldName: "sevCatName",
          display: true,
          fieldLabel: "服务条目名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "sevStartDate",
          display: true,
          fieldLabel: "服务起始时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "sevFirChargeDate",
          display: true,
          fieldLabel: "首次收费时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "firstChargePrice",
          display: true,
          fieldLabel: "首次收费金额",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "currencyType",
          display: true,
          fieldLabel: "币种",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "sevStatus",
          display: true,
          fieldLabel: "使用状态",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "templateName",
          display: true,
          fieldLabel: "套餐名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "speCondition",
          display: true,
          fieldLabel: "服务费特殊条件",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "note",
          display: true,
          fieldLabel: "备注",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //服务费表头
      deviceColumn: [
        {
          fieldName: "equCatName",
          display: true,
          fieldLabel: "设备费条目",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "equPrice",
          display: true,
          fieldLabel: "价格",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "currencyType",
          display: true,
          fieldLabel: "币种",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "equInstallDate",
          display: true,
          fieldLabel: "安装日期",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "templateName",
          display: true,
          fieldLabel: "套餐名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "isCrtaxfee",
          display: true,
          fieldLabel: "已生成费用",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "sevStatus",
          display: true,
          fieldLabel: "条目状态",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "noteDeposit",
          display: true,
          fieldLabel: "备注",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //设备费表头
      installColumn: [
        {
          fieldName: "insCatName",
          display: true,
          fieldLabel: "安装费条目",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "insPrice",
          display: true,
          fieldLabel: "价格",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "currencyType",
          display: true,
          fieldLabel: "币种",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "installDate",
          display: true,
          fieldLabel: "安装日期",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "templateName",
          display: true,
          fieldLabel: "套餐名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "isCrtaxfee",
          display: true,
          fieldLabel: "已生成费用",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "sevStatus",
          display: true,
          fieldLabel: "条目状态",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "speIns",
          display: true,
          fieldLabel: "安装费特殊条件",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "note",
          display: true,
          fieldLabel: "备注",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //安装费用表头
      equPriceList: [], // 设备费信息
      insPriceList: [], // 安装费信息
      sevDetPriceList: [] // 服务费信息
    };
  },
  mixins: [base],
  computed: {
    columnData() {
      if (this.clickIndex == 0) {
        return this.serviceColumnData;
      } else if (this.clickIndex == 1) {
        return this.deviceColumn;
      } else {
        return this.installColumn;
      }
    },
    tableData() {
      if (this.clickIndex == 0) {
        return this.sevDetPriceList;
      } else if (this.clickIndex == 1) {
        return this.equPriceList;
      } else {
        return this.insPriceList;
      }
    }
  },
  components: {},
  watch: {},
  methods: {
    init(terminalId) {
      this.getTerminalPrice(terminalId);
    },
    async getTerminalPrice(terminalId) {
      try {
        this.loading = true;
        let res = await getTerminalPrice({ id: terminalId });
        this.insPriceList = this.getFreezeResponse(res, {
          path: "data.insPriceList"
        });
        this.equPriceList = this.getFreezeResponse(res, {
          path: "data.equPriceList"
        });
        this.sevDetPriceList = this.getFreezeResponse(res, {
          path: "data.sevDetPriceList"
        });
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //点击二级切换
    switchPage(idx) {
      this.clickIndex = idx;
    }
  },
  created() {}
};
</script>
<style lang="scss" scoped>
.second-change-type {
  margin: 15px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .left-btn {
    .gray {
      background: #edf0f5;
      border: none;
      color: rgb(137, 140, 145);
    }
    .blue {
      background: #46bfea;
      color: #fff;
      border: none;
    }
  }
}
</style>
